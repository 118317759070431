<template>
  <div class="w-full h-full flex flex-col justify-end items-center overflow-hidden relative">

    <div class="w-full h-full flex justify-center items-start absolute z-10">
      <img class="w-[200px] object-contain translate-y-[10px]" src="/calendar.png" alt="">
    </div>

    <div class="w-full h-[50%] bg-gradient-to-b from-transparent to-black/50 z-20 absolute rounded-b-md flex justify-start items-end p-4">
      <div class="w-full z-20">
        <h2 class="text-xl font-bold font-brand text-white mb-2">Oxbull Calendar</h2>
        <div class="w-full flex justify-between items-end space-x-4">
          <p class="text-zinc-400 text-sm" >
            The most bullish calendar ever.</p>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>