<template>
  <div class="w-full flex flex-col justify-between items-center p-4 h-full">

    <div class="flex-grow w-full flex justify-center items-center flex-col">


      <div class="w-full flex justify-center items-center">
        <img class="w-16 h-16" src="https://oxbull-presale.s3.amazonaws.com/tier1.svg" alt="">
      </div>

      <div class="w-full flex justify-center items-center -mt-2">
        <img class="w-16 h-16" src="https://oxbull-presale.s3.amazonaws.com/tier2.svg" alt="">
        <img class="w-16 h-16" src="https://oxbull-presale.s3.amazonaws.com/tier3.svg" alt="">
      </div>


    </div>


    <div class="w-full">
      <p class="text-xl font-bold font-brand text-white mb-2">Tiers for all</p>
      <p class="text-zinc-400 text-sm" >Oxbull believes crypto is for everyone, that's why Oxbull tier system is designed to be accessible. There are three different tiers in Oxbull.</p>
    </div>

  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>