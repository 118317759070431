<template>
  <div class="w-full h-full flex justify-center items-center flex-col relative overflow-hidden">
    <div class="w-full h-full flex justify-center items-center absolute translate-y-[-10%]">
      <Icon class="text-white sm:text-6xl text-4xl" name="file-icons:telegram"></Icon>

      <div class="tiercircle sm:w-[16rem] sm:h-[16rem] w-[8rem] h-[8rem] border border-white/20 rounded-full absolute flex justify-center items-center">
        <div class="sm:w-[16rem] w-[8rem] h-3 flex justify-center items-center relative">
          <div class="sm:w-[8rem] w-[4rem] h-3 flex justify-start items-center origin-right absolute left-0">
            <div class="sm:w-16 sm:h-16 w-8 h-8 sm:-ml-7 ml-[-0.875rem]">
              <img class=" tiericon" src="/t1.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[8rem] w-[4rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[120deg]">
            <div class="rotate-[-120deg] sm:w-16 sm:h-16 w-8 h-8 sm:-ml-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t1.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[8rem] w-[4rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[240deg]">
            <div class="rotate-[-240deg] sm:w-16 sm:h-16 w-8 h-8 sm:-ml-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="tiercircle sm:w-[34rem] sm:h-[34rem] w-[17rem] h-[17rem] border border-white/20 rounded-full absolute flex justify-center items-center">
        <div class="sm:w-[34rem] w-[17rem] h-3 flex justify-center items-center relative">
          <div class="sm:w-[17rem] w-[8.5rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[-10deg]">
            <div class="rotate-[-10deg] sm:w-14 sm:h-14 w-7 h-7 ml-[-0.875rem] sm:-ml-7">
              <img class="tiericon" src="/t2.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[17rem] w-[8.5rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[170deg]">
            <div class="rotate-[-170deg] sm:w-14 sm:h-14 w-7 h-7 ml-[-0.875rem] sm:-ml-7">
              <img class="tiericon" src="/t2.png" alt="" />
            </div>
          </div>

          <!--<div class="sm:w-[17rem] w-[8.5rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[225deg]">
            <div class="rotate-[-225deg] sm:w-14 sm:h-14 w-7 h-7 ml-[-0.875rem] sm:-ml-7">
              <img class="tiericon" src="/t2.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[17rem] w-[8.5rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[315deg]">
            <div class="rotate-[-315deg] sm:w-14 sm:h-14 w-7 h-7 ml-[-0.875rem] sm:-ml-7">
              <img class="tiericon" src="/t2.png" alt="" />
            </div>
          </div>-->
        </div>
      </div>

      <div class="tiercircle sm:w-[52rem] sm:h-[52rem] w-[26rem] h-[26rem] border border-white/20 rounded-full absolute flex justify-center items-center">
        <div class="sm:w-[52rem] w-[26rem] h-3 flex justify-center items-center relative">
          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[190deg]">
            <div class="rotate-[190deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[370deg]">
            <div class="rotate-[370deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <!--<div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[90deg]">
            <div class="rotate-[-90deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[120deg]">
            <div class="rotate-[-120deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[150deg]">
            <div class="rotate-[-150deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[180deg]">
            <div class="rotate-[-180deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[210deg]">
            <div class="rotate-[-210deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[240deg]">
            <div class="rotate-[-240deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[270deg]">
            <div class="rotate-[-270deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[300deg]">
            <div class="rotate-[-300deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[330deg]">
            <div class="rotate-[-330deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>

          <div class="sm:w-[26rem] w-[13rem] h-3 flex justify-start items-center origin-right absolute left-0 rotate-[360deg]">
            <div class="rotate-[-360deg] sm:w-14 sm:h-14 sm:-ml-7 w-7 h-7 ml-[-0.875rem]">
              <img class="tiericon" src="/t3.png" alt="" />
            </div>
          </div>-->
        </div>
      </div>

      <!-- <div class="w-[34rem] h-[34rem] border  border-white/20 rounded-full absolute"> </div> -->
      <!-- <div class="w-[52rem] h-[52rem] border  border-white/20 rounded-full absolute"> </div> -->
    </div>

    <div class="w-full z-20 p-4 absolute bottom-0 left-0">
      <h2 class="text-xl font-bold font-brand text-white mb-2">Private Groups</h2>
      <div class="w-full flex justify-between items-end space-x-4">
        <p class="text-zinc-400 text-sm">Holding a tier in Oxbull also grants you access to exclusive Oxbull private groups, where you connect with fellow investors of the same tier. This is where the magic happens: exclusive information, early insights, expert advice and more - all at your fingertips.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const { $anime } = useNuxtApp();

// onMounted(() => {
//   $anime({
//     targets: ".tiercircle",
//     rotate: 360,
//     duration: 30000,
//     easing: "linear",
//     loop: true,
//   });

//   $anime({
//     targets: ".tiericon",
//     rotate: -360,
//     duration: 30000,
//     easing: "linear",
//     loop: true,
//   });
// });
</script>

<style></style>
